import { addHours, differenceInMinutes, format, setHours, setMinutes, setSeconds, subHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { css } from 'styled-components';
import { lazy, ReactNode } from 'react';
import { AxiosError } from 'axios';
import { ErrorCode, ErrorResponse, ValidationErrorResponse } from './types';
import { UseFormSetError } from 'react-hook-form';
import { SnackFuncProps } from './hooks/use-snack';
import * as xss from 'xss';
import { CourseDetailForm, CourseDetailWithHelpers } from '@/web/types';

export const getInitials = (str: string) =>
  (str || '')
    .split(' ')
    .map((part) => part[0])
    .join('')
    .toUpperCase();

export interface NameParts {
  firstName: string;
  insertion: string | null;
  lastName: string | null;
}

/**
 * Attempt to extract name parts from the given string.
 * @param name The string to extract name parts from.
 * @return NameParts The parts of the name.
 */
export const getNameParts = (name: string): NameParts => {
  const parts = name.split(' ');

  const result: NameParts = {
    firstName: parts[0],
    insertion: null,
    lastName: null,
  };

  if (parts.length === 1) {
    return result;
  }

  result.lastName = parts[parts.length - 1];

  if (parts.length === 2) {
    return result;
  }

  result.insertion = parts.slice(1, parts.length - 2).join(' ');

  return result;
};

export const getFromStorage = (storeItem: string, parse = false, storage = localStorage) => {
  const item = storage.getItem(storeItem);

  if (item) {
    if (parse) return JSON.parse(item);

    return item;
  }

  return null;
};

export const setInStorage = (storeItem: string, value: any, storage = localStorage) => {
  storage.setItem(storeItem, value);
};

export const removeFromStorage = (storeItem: string, storage = localStorage) => {
  storage.removeItem(storeItem);
};

export const formatAmount = (amount: number, currency = 'EUR', alwaysShowFranctionDigits = true) => {
  const hasFractionalPart = alwaysShowFranctionDigits || amount % 1 !== 0;

  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
    minimumFractionDigits: hasFractionalPart ? 2 : 0,
    maximumFractionDigits: hasFractionalPart ? 2 : 0,
  }).format(amount);
};

export const getCssFromComponent = (Component: any) => {
  return css`
    ${Component.componentStyle.baseStyle.rules}
    ${Component.componentStyle.rules}
  `;
};

export const sendEvent = (eventName: string) => {
  if (window.gtag) {
    window.gtag('event', eventName);
  }
};

export const parseCourseISO = (isostring: string, course: any) => {
  const offset = course.utcOffset || (-1 * new Date().getTimezoneOffset()) / 60;
  const parsedIsoString = utcToZonedTime(isostring, 'UTC');

  return Math.sign(offset) === -1
    ? subHours(parsedIsoString, Math.abs(offset))
    : addHours(parsedIsoString, Math.abs(offset));
};

export const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export const dayOfWeekToLabel: { [key: number]: string } = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};

export const dayOfWeekToFullLabel: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

/**
 * Generate labels for a period of time.
 *
 * @param startDate The start date.
 * @param endDate The end date.
 *
 * @returns string[] The labels.
 */
export const generateLabelsForPeriod = (startDate: Date, endDate: Date) => {
  const labels = [];

  // Start at the first day.
  let currentDate = startDate;

  while (currentDate <= endDate) {
    labels.push(currentDate.toLocaleDateString());
    // Add one day to the current day.
    currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  }

  return labels;
};

export const isErrorResponse = (error: any): error is AxiosError<ErrorResponse | ValidationErrorResponse> => {
  return (
    (error as AxiosError<ErrorResponse | ValidationErrorResponse>).isAxiosError &&
    error.response !== undefined &&
    error.response.data.code !== undefined
  );
};

/**
 * Handle validation errors from the API and apply them to the form.
 *
 * @param error The error from API.
 * @param setError The function to set the error.
 */
export const handleValidationError = (
  error: AxiosError<ValidationErrorResponse | any> | unknown,
  setError: UseFormSetError<any> | undefined,
  errorMessage: SnackFuncProps,
) => {
  if (!isErrorResponse(error)) {
    if (errorMessage) {
      errorMessage('error.snack.title', { detailedMessage: 'error.snack.message' });
    }
    return;
  }

  const response = error.response?.data;

  if (response && response.code === ErrorCode.VALIDATION_FAILED) {
    const validationErrors = response as ValidationErrorResponse;

    if (setError) {
      validationErrors.errors.forEach((error) => {
        setError(error.field as any, {
          message: error.message,
        });
      });
    }

    errorMessage('validation.snack.error.title', { detailedMessage: 'validation.snack.error.message' });
  } else {
    errorMessage('error.snack.title', { detailedMessage: 'error.snack.message' });
  }
};

export const resizeImage = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = function () {
      const canvas = document.createElement('canvas');
      const maxWidth = 800;
      const maxHeight = 600;
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');

      if (ctx === null) {
        console.error('Could not get canvas context.');
        return;
      }

      ctx.drawImage(image, 0, 0, width, height);

      // Convert canvas to Blob
      canvas.toBlob(
        function (blob) {
          // Convert blob to File
          if (blob) {
            const resizedFile = new File([blob], 'profile.jpg', { type: 'image/jpeg', lastModified: Date.now() });

            resolve(resizedFile);
          } else {
            reject(new Error('Could not resize image.'));
          }

          URL.revokeObjectURL(image.src);
        },
        'image/jpeg',
        0.8,
      );
    };
  });
};

/**
 * Adds 'st' or 'th' to a number.
 *
 * @param value
 */
export const addOrdinalSuffix = (value: number) => {
  const j = value % 10;
  const k = value % 100;

  if (j === 1 && k !== 11) {
    return `${value}st`;
  }

  if (j === 2 && k !== 12) {
    return `${value}nd`;
  }

  if (j === 3 && k !== 13) {
    return `${value}rd`;
  }

  return `${value}th`;
};

/**
 * Pluralize the word based on the count.
 *
 * @param singular The singular word.
 * @param plural The plural word.
 * @param count The count.
 *
 * @returns The pluralized word.
 */
export const pluralize = (singular: string, plural: string, count: number) => {
  return count === 1 ? singular : plural;
};

/**
 * Filter HTML for XSS attacks.
 *
 * @param unsafe The unsafe HTML.
 *
 * @returns The filtered HTML.
 */
export const filterHtmlContent = (unsafe: string) => {
  return xss.filterXSS(unsafe.replace(/\n/g, '<br />'), {
    whiteList: {
      p: [],
      br: [],
      div: [],
    },
  });
};

export const courseToForm = (course: CourseDetailWithHelpers, courseTypes: any): CourseDetailForm => {
  const date = parseCourseISO(course.originalStartAt || course.startAt, course);

  return {
    name: course.name,
    teacherIds: course.teachers?.map((teacher: any) => teacher.id),
    repeat: course.recurringPattern?.recurringType || 'ONCE',
    typeIds: course.activities?.map((typeObj: any) => {
      return courseTypes?.find((item: any) => item.id === typeObj.id);
    })?.[0]?.value,
    primaryLocationId: course.businessLocation?.id,
    date,
    capacity: course.capacity,
    from: format(date, 'HH:mm'),
    duration: '' + differenceInMinutes(parseCourseISO(course.originalEndAt || course.endAt, course), date),
  };
};

/** Checks whether the current environment is the app */
export const isApp = () => window.ReactNativeWebView !== undefined;

/** Checks whether the given app version is after the given app version */
export const isAppVersionSince = (version: string) => {
  if (window.AppVersion === undefined) {
    return false;
  }

  return window.AppVersion >= parseInt(version.split('.').join(''), 10);
};

export const getTranslatorStringForPlurals = (languageKey: string, isPlural: boolean) => {
  return isPlural ? `${languageKey}.other` : `${languageKey}.one`;
};

export const lowerCaseFirstLetter = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const dateWithTime = (date: Date, time: string): Date => {
  const [hour, minutes] = time.split(':');

  let newDate = setHours(date, parseInt(hour));
  newDate = setMinutes(newDate, parseInt(minutes));
  newDate = setSeconds(newDate, 0);

  return newDate;
};

export const highlightQuery = (str: string, query: string): ReactNode => {
  const parts = str.split(new RegExp(`(${query})`, 'gi'));

  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className="font-median">
        {part}
      </span>
    ) : (
      part
    ),
  );
};
