import { gaxios } from '@/api';
import { Paginator } from '@/common/types';
import { createSearchParams } from 'react-router-dom';
import { DEFAULT_TABLE_ROW_SIZE } from '../common/constants';
import {
  ActiveSubscriptionsStatistics,
  Business,
  BusinessUser,
  BusinessUserSignupResponse,
  BusinessWithUser,
  CancelledSubscriptionsStatistics,
  CheckinStatistics,
  Course,
  CourseType,
  CourseTypeActivityStatistics,
  CourseTypeMemberStatistics,
  CourseWithHelpers,
  EventNote,
  GymlyInvoice,
  Invoice,
  InvoiceStatus,
  Location,
  Membership,
  NewMembersStatistics,
  NewSubscriptionsStatistics,
  PaymentStatus,
  PosStatistics,
  PrivacyPolicy,
  ReservationsStatistics,
  RevenueStatistics,
  Room,
  StoredEventResponse,
  TermsAndConditions,
  TrainingFrequencyDistributionStatistics,
  UniqueMembersPerCourseTypeStatistics,
  UrlTarget,
} from '../web/types';
import { addHelpersToCourses } from '@/web/utils';
import { isAppVersionSince } from '@/common/utils';
import { format } from 'date-fns';

export const FETCH_BUSINESS = 'FETCH_BUSINESS';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_BUSINESS_MEMBER = 'FETCH_BUSINESS_MEMBER';
export const FETCH_BUSINESS_MEMBERS = 'FETCH_BUSINESS_MEMBERS';
export const FETCH_BUSINESS_LOCATIONS = 'FETCH_BUSINESS_LOCATIONS';
export const FETCH_BUSINESS_MEMBERSHIPS = 'FETCH_BUSINESS_MEMBERSHIPS';
export const FETCH_BUSINESS_COURSE_TYPES = 'FETCH_BUSINESS_COURSE_TYPES';

export const getBusinesses = async (): Promise<any> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses?page=1&size=10`,
  });

  return resp.data;
};

export const getBusinessLocations = async (uuid: string): Promise<Array<Location>> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${uuid}/locations`,
  });

  return resp.data;
};

export const getBusinessMembershipTypes = async (uuid: string): Promise<Membership[]> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${uuid}/memberships`,
  });

  return resp.data;
};

export interface CreateBusinessPayload {
  name: string;
  type: string;
}

export const createBusiness = async (data: CreateBusinessPayload): Promise<Business> => {
  const url = `/businesses`;
  const resp = await gaxios({
    method: 'POST',
    url,
    data,
  });

  return resp.data;
};

export const getBusiness = async (uuid: string): Promise<Business> => {
  const url = `/businesses/${uuid}`;
  const resp = await gaxios({
    method: 'GET',
    url,
  });

  return resp.data;
};

export const getBusinessWithUser = async (uuid: string): Promise<BusinessWithUser> => {
  const url = `/user/businesses/${uuid}`;
  const resp = await gaxios({
    method: 'GET',
    url,
  });

  return resp.data;
};

export const getBusinessMember = async (businessUuid: string, userUuid: string): Promise<BusinessUser> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/users/${userUuid}`,
  });

  return resp.data;
};

export const getBusinessMemberEvents = async (
  businessUuid: string,
  userUuid: string,
  token: string | null,
): Promise<StoredEventResponse> => {
  const params: { token?: string; size: number } = {
    size: 20,
  };

  if (token !== null) {
    params.token = token;
  }

  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/users/${userUuid}/events`,
    params,
  });

  return resp.data;
};

export const deleteBusinessMember = async (businessUuid: string, userUuid: string): Promise<any> => {
  const resp = await gaxios({
    method: 'DELETE',
    url: `/businesses/${businessUuid}/users/${userUuid}`,
  });

  return resp.data;
};
(window as any).deleteBusinessMember = deleteBusinessMember;

export const addUserToBusiness = async (uuid: string, data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/user/businesses/${uuid}/users`,

    data,
  });

  return resp.data;
};

export const guestInvite = async (uuid: string, data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/businesses/${uuid}/guest/guest-invite`,

    data,
  });

  return resp.data;
};

export const deleteGuestFromCourse = async (uuid: string, guestId: string): Promise<any> => {
  const resp = await gaxios({
    method: 'DELETE',
    url: `/businesses/${uuid}/guest/${guestId}`,
  });

  return resp.data;
};

export const getBusinessUserInvoices = async (businessUuid: string, userUuid: string, params: any): Promise<any> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/users/${userUuid}/invoices`,
    params,
  });

  return resp.data;
};

export const getInvoicePdf = async (businessUuid: string, invoiceUuid: string): Promise<any> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/invoices/${invoiceUuid}/pdf`,
    responseType: 'blob',
  });

  const fileURL = URL.createObjectURL(resp.data);
  window.open(fileURL);

  return resp.data;
};

export const getInvoicePdfLink = async (businessId: string, invoiceId: string): Promise<{ url: string }> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/invoices/${invoiceId}/pdf-link`,
  });

  return resp.data;
};

export const getBillingInvoicePdf = async (businessUuid: string, invoiceUuid: string): Promise<any> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/billing/invoices/${invoiceUuid}/pdf`,
    responseType: 'blob',
  });

  const fileURL = URL.createObjectURL(resp.data);
  window.open(fileURL);

  return resp.data;
};

export const getTermsAndConditionsLegacyPdf = async (businessUuid: string): Promise<any> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/terms-and-conditions/pdf`,
    responseType: 'blob',
  });

  const fileURL = URL.createObjectURL(resp.data);
  window.open(fileURL);

  return resp.data;
};

export const getTermsAndConditionsPdf = async (businessUuid: string): Promise<any> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/terms-and-conditions/current/pdf`,
    responseType: 'blob',
  });

  const fileURL = URL.createObjectURL(resp.data);
  window.open(fileURL);

  return resp.data;
};

export const getTermsAndConditions = async (businessUuid: string): Promise<TermsAndConditions | null> => {
  const resp = await gaxios<{ termsAndConditions: TermsAndConditions }>({
    method: 'GET',
    url: `/businesses/${businessUuid}/terms-and-conditions/current`,
  });

  return resp.data.termsAndConditions;
};

export const getPrivacyPolicy = async (businessUuid: string): Promise<PrivacyPolicy | null> => {
  const resp = await gaxios<{ privacyPolicy: PrivacyPolicy }>({
    method: 'GET',
    url: `/businesses/${businessUuid}/privacy-policy/current`,
  });

  return resp.data.privacyPolicy;
};

export const signupAtBusiness = async (
  businessUuid: string,
  data: { membershipId: string; businessLocationId: string; discountCode: string | null },
): Promise<BusinessUserSignupResponse> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/user/businesses/${businessUuid}/signup`,
    data,
  });

  return resp.data;
};

export const sendOnboadingEmail = async (businessUuid: string, userUuid: string): Promise<void> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/businesses/${businessUuid}/users/${userUuid}/send-onboarding-mail`,
  });

  return resp.data;
};

export const getBusinessCourseTypes = async (
  businessUuid: string,
  params: any = { page: 0, size: 100 },
): Promise<any> => {
  const queryParams = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/course-types?${queryParams}`,
  });

  return resp.data;
};

export const addBusinessLocation = async (businessUuid: string, data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/businesses/${businessUuid}/locations`,

    data,
  });

  return resp.data;
};

export const addBusinessCourseType = async (businessUuid: string, data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/staff/businesses/${businessUuid}/courseTypes`,

    data,
  });

  return resp.data;
};

export const deleteBusinessCourseType = async (businessUuid: string, courseTypeUuid: any): Promise<any> => {
  const resp = await gaxios({
    method: 'DELETE',
    url: `/staff/businesses/${businessUuid}/courseTypes/${courseTypeUuid}`,
  });

  return resp.data;
};

export const updateBusinessCourseType = async (
  businessUuid: string,
  courseTypeUuid: string,
  data: any,
): Promise<any> => {
  const resp = await gaxios({
    method: 'PUT',
    url: `/staff/businesses/${businessUuid}/courseTypes/${courseTypeUuid}`,

    data,
  });

  return resp.data;
};

export const uploadCourseTypeImage = async (uuid: string, businessUuid: string, file: File): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);

  const resp = await gaxios({
    method: 'POST',
    url: `/staff/businesses/${businessUuid}/courseTypes/${uuid}/image`,

    data: formData,
  });

  return resp.data;
};

export const updateBusinessLocation = async (businessUuid: string, locationUuid: string, data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'PUT',
    url: `/businesses/${businessUuid}/locations/${locationUuid}`,

    data,
  });

  return resp.data;
};

export const addBusinessCourse = async (businessUuid: string, locationUuid: string, data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/staff/businesses/${businessUuid}/locations/${locationUuid}/courses`,

    data,
  });

  return resp.data;
};

export const updateBusinessCourse = async (
  businessUuid: string,
  locationUuid: string,
  courseUuid: string,
  data: any,
): Promise<Course> => {
  const resp = await gaxios({
    method: 'PUT',
    url: `/staff/businesses/${businessUuid}/locations/${locationUuid}/courses/${courseUuid}`,

    data,
  });

  return resp.data;
};

export const createNotification = async (uuid: string, data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/businesses/${uuid}/notifications`,

    data,
  });

  return resp.data;
};

export const FETCH_WAITLIST = 'FETCH_WAITLIST';
export const getUserWaitlist = async (
  businessUuid: string,
  activities: Record<string, CourseType>,
  params = {},
): Promise<CourseWithHelpers[]> => {
  const requestParams = {
    size: DEFAULT_TABLE_ROW_SIZE,
    page: 1,
    ...params,
  };

  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/courses/waitlist`,
    params: requestParams,
  });

  return addHelpersToCourses(resp.data?.content, activities);
};

export const getAttendanceAnalytics = async (uuid: string, params = {}): Promise<any> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${uuid}/analytics/attendance/courses?${query}`,
  });

  return resp.data || [];
};

interface CheckinsResponse {
  id: string;
  date: string;
  businessUser: BusinessUser;
  businessLocation: Location;
}

export const getCheckinsList = async (uuid: string, locationId: string): Promise<CheckinsResponse[]> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${uuid}/analytics/checkin/${locationId}?page=0&size=10`,
  });

  return resp.data?.content || [];
};

export const getRevenueStatistics = async (businessId: string, params = {}): Promise<RevenueStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/analytics/revenue?${query}`,
  });

  return resp.data;
};

export const getPosStatistics = async (businessId: string, params = {}): Promise<PosStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/pos/statistics?${query}`,
  });

  return resp.data;
};

export const getCourseTypeActivityStatistics = async (
  businessId: string,
  params = {},
): Promise<CourseTypeActivityStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/courses/statistics/course-type-activity?${query}`,
  });

  return resp.data;
};

export const getTrainingFrequencyDistributionStatistics = async (
  businessId: string,
  params = {},
): Promise<TrainingFrequencyDistributionStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/courses/statistics/training-frequency-distribution?${query}`,
  });

  return resp.data;
};

export const getCourseTypeMemberStatistics = async (
  businessId: string,
  courseTypeId: string,
  params = {},
): Promise<CourseTypeMemberStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/courses/statistics/course-type-members/${courseTypeId}?${query}`,
  });

  return resp.data;
};

export const getUniqueMembersPerCourseTypeStatistics = async (
  businessId: string,
  params = {},
): Promise<UniqueMembersPerCourseTypeStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/courses/statistics/unique-members-per-course-type?${query}`,
  });

  return resp.data;
};

export const getNewUsersByDayStatistics = async (businessId: string, params = {}): Promise<NewMembersStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/users/statistics/new-users-per-day?${query}`,
  });

  return resp.data;
};

export const getNewSubscriptionsPerDayStatistics = async (
  businessId: string,
  params = {},
): Promise<NewSubscriptionsStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/users/statistics/new-subscriptions-per-day?${query}`,
  });

  return resp.data;
};

export const getCancelledSubscriptionsPerDayStatistics = async (
  businessId: string,
  params = {},
): Promise<CancelledSubscriptionsStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/users/statistics/cancelled-subscriptions-per-day?${query}`,
  });

  return resp.data;
};

export const getActiveSubscriptionsStatistics = async (
  businessId: string,
  params = {},
): Promise<ActiveSubscriptionsStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/users/statistics/active-subscriptions-per-week?${query}`,
  });

  return resp.data;
};

export const getCheckinStatistics = async (
  businessId: string,
  locationId: string,
  params = {},
): Promise<CheckinStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/analytics/checkin/${locationId}/statistics?${query}`,
  });

  return resp.data;
};

export const getReservationsStatistics = async (
  businessId: string,
  locationId: string,
  params = {},
): Promise<ReservationsStatistics> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessId}/locations/${locationId}/statistics/reservations-per-day-and-time?${query}`,
  });

  return resp.data;
};

export type UserNoShow = {
  businessUser: BusinessUser;
  noShows: number;
};

// To do: extract to more generic solution
type PaginateParams<T> = T & {
  page?: number;
  size?: number;
};

export const getPaginatedNoShows = async ({
  page = 0,
  size = 20,
  businessUuid,
}: PaginateParams<{ businessUuid: string }>): Promise<Paginator<UserNoShow>> => {
  const resp = await gaxios<Paginator<UserNoShow>>({
    method: 'GET',
    url: `/businesses/${businessUuid}/analytics/attendance/no-shows?page=${page}&size=${size}`,
  });

  return resp.data;
};

export const getPaginatedInvoices = async ({
  page = 0,
  size = 20,
  businessUuid,
  startDate,
  endDate,
  location,
  status,
}: PaginateParams<{
  businessUuid: string;
  startDate?: string;
  endDate?: string;
  location?: string;
  status?: InvoiceStatus[] | null;
}>): Promise<Paginator<Invoice>> => {
  const params: {
    page: string;
    size: string;
    startDate?: string;
    endDate?: string;
    locations?: string[];
    status?: string[];
  } = {
    page: page.toString(),
    size: size.toString(),
  };

  if (startDate) {
    params.startDate = startDate;
  }

  if (endDate) {
    params.endDate = endDate;
  }

  if (status) {
    params.status = status;
  }

  if (location) {
    params.locations = [location];
  }

  const query = createSearchParams(params);
  const resp = await gaxios<Paginator<Invoice>>({
    method: 'GET',
    url: `/businesses/${businessUuid}/invoices?${query}`,
  });

  return resp.data;
};

export const getPaginatedBillingInvoices = async ({
  page = 0,
  size = 20,
  businessUuid,
}: PaginateParams<{ businessUuid: string }>): Promise<Paginator<GymlyInvoice>> => {
  const params: {
    page: string;
    size: string;
  } = {
    page: page.toString(),
    size: size.toString(),
  };

  const query = createSearchParams(params);
  const resp = await gaxios<Paginator<GymlyInvoice>>({
    method: 'GET',
    url: `/businesses/${businessUuid}/billing/invoices?${query}`,
  });

  return resp.data;
};

export const getBusinessUserNotes = async ({
  page = 0,
  size = 10,
  businessId,
  userId,
}: PaginateParams<{ businessId: string; userId: string }>): Promise<Paginator<EventNote>> => {
  const params: {
    page: string;
    size: string;
  } = {
    page: page.toString(),
    size: size.toString(),
  };

  const query = createSearchParams(params);
  const resp = await gaxios<Paginator<EventNote>>({
    method: 'GET',
    url: `/businesses/${businessId}/users/${userId}/notes?${query}`,
  });

  return resp.data;
};

export const createNoteForEvent = async (businessId: string, eventId: string, content: string): Promise<EventNote> => {
  const resp = await gaxios<EventNote>({
    method: 'POST',
    url: `/businesses/${businessId}/events/${eventId}/notes`,
    data: {
      content,
    },
  });

  return resp.data;
};

export const createNoteForUser = async (businessId: string, userId: string, content: string): Promise<EventNote> => {
  const resp = await gaxios<EventNote>({
    method: 'POST',
    url: `/businesses/${businessId}/users/${userId}/notes`,
    data: {
      content,
    },
  });

  return resp.data;
};

export const getAttendanceAnalyticsCsv = async (uuid: string, params: any = {}): Promise<any> => {
  const query = createSearchParams(params);
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${uuid}/analytics/attendance/courses/csv?${query}`,
    responseType: 'blob',
  });

  const url = window.URL.createObjectURL(new Blob([resp.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${params.startDate}-${params.endDate}.csv`);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);

  return resp.data || [];
};

/**
 * Start the purchase of a membership for a user.
 *
 * @param businessId The ID of the business to purchase the membership from
 * @param membershipId The ID of the membership to purchase
 * @param customStartDate Optional custom start date for the membership
 *
 * @returns Payment link and status token
 */
export const startUserMembershipPurchase = async (
  businessId: string,
  membershipId: string,
  customStartDate: Date | null = null,
): Promise<{
  paymentUrl: string;
  paymentStatusToken: string;
  customStartDate: Date | null;
}> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/user/businesses/${businessId}/memberships`,
    data: {
      membershipId,
      customStartDate: customStartDate ? format(customStartDate, 'yyyy-MM-dd') : null,
      // App redirects are supported after 1.2.0, otherwise redirect back to the browser.
      target: isAppVersionSince('1.2.0') ? UrlTarget.APP : UrlTarget.WEB,
    },
  });

  return resp.data;
};

/**
 * Start the payment of an open invoice of the user.
 *
 * @param businessId
 * @param invoiceId
 *
 * @returns Payment link and status token
 */
export const startUserInvoicePayment = async (
  businessId: string,
  invoiceId: string,
): Promise<{ paymentLink: string; paymentStatusToken: string }> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/user/businesses/${businessId}/invoices/${invoiceId}/session`,
  });

  return resp.data;
};

/**
 * Get the status of a payment
 *
 * @param token The token to access the payment status
 *
 * @returns The status of the payment
 */
export const getPaymentStatus = async (token: string): Promise<{ status: PaymentStatus }> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/payments/status/${token}`,
  });

  return resp.data;
};

/**
 * Get all the memberships available to the current user.
 *
 * @param businessId
 *
 * @returns The memberships available to the user.
 */
export const getUserAvailableMemberships = async (businessId: string): Promise<Membership[]> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/user/businesses/${businessId}/memberships`,
  });

  return resp.data;
};

/**
 * Get rooms for a business.
 *
 * @param businessId The ID of the business.
 *
 * @returns A list of rooms.
 */
export const getRooms = async (businessId: string): Promise<Room[]> => {
  const resp = await gaxios<{ rooms: Room[] }>({
    method: 'GET',
    url: `/businesses/${businessId}/rooms`,
  });

  return resp.data.rooms;
};
